<template>
  <div class="publicServiceMap">
    <iframe
      src="https://nfggy.com/nanfan-web/social-services/map/index.html"
      style="width: 100%; height: 800px;"
      frameborder="0"
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      startTime: null,
      pageName: "南繁服务(publicServiceMap.vue)",
    };
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
          .then(response => {
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestSuccessTime", requestTime);
            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "success",
                requestResult: response.toString()
              }
            })
          })
          .catch(error=>{
            let requestEndTime = performance.now();
            let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
            console.log("requestErrorTime", requestTime);

            window.SZHN_FIRE_BEAR?.trackEvent({
              key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
              segmentation: {
                requestTime: requestTime,
                requestStatu: "error",
                requestResult: error.toString()
              }
            })
          })
    },
  }
};
</script>
<style lang="less" scoped>
.publicServiceMap {
  width: 100%;
  height: 800px;
}
</style>
